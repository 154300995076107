import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './orderForm.scss';
import {injectIntl, WrappedComponentProps as InjectedIntlProps} from 'react-intl';
import 'react-multi-carousel/lib/styles.css';
import {Dispatch} from "redux";
import {widgetToggleShowContactModal} from "../../../store/widget/widget.actions";
import {connect} from "react-redux";
import {AppState} from "../../../store/model";
import {isShowContactModal} from "../../../store/widget/widget.selectors";

interface ConnectedState {
    showContactModal: boolean;
}

interface ConnectedDispatch {
    toggleShowContactModal: (isShowContactModal: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        toggleShowContactModal: (isShowContactModal) => dispatch(widgetToggleShowContactModal(isShowContactModal)),
    };
};

function mapStateToProps(state: AppState): ConnectedState {
    return {
        showContactModal: isShowContactModal(state),
    }
}

class OnlineFormComponentInner extends React.PureComponent<ConnectedState & ConnectedDispatch & InjectedIntlProps> {

    constructor(props: ConnectedState & ConnectedDispatch & InjectedIntlProps) {
        super(props);

        this.toggleShowOnlineForm = this.toggleShowOnlineForm.bind(this);
    }

    toggleShowOnlineForm() {
        this.props.toggleShowContactModal(!this.props.showContactModal);
    }

    public render() {

        const { intl } = this.props;

        return (<div className={'online-form'}>
            <p>
                {intl.formatMessage({id: 'online-form.text'})}
            </p>
            <button
                onClick={this.toggleShowOnlineForm}>
                {intl.formatMessage({id: 'online-form.button1'})}
            </button>

        </div>);
    }
}

export const OnlineFormComponent = injectIntl(connect(mapStateToProps, mapDispatchToProps)(OnlineFormComponentInner));
