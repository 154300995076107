import {WIDGET_CHANGE_TRANSLATIONS, WIDGET_TOGGLE_SHOW_CONTACT_MODAL, widgetToggleShowContactModal} from './widget.actions';
import { Reducer } from 'redux';
import { WidgetStore } from "./widget.model";

const INITIAL_STATE: WidgetStore = {
    features: [],
    advantages: [],
    offers: [],
    sliders: [],
    reviews: [],
    categories: [],
    showContactModal: false,
};

export const WidgetReducer: Reducer<WidgetStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WIDGET_CHANGE_TRANSLATIONS: {
            return {
                ...state,
                features: action.payload.features,
                advantages: action.payload.advantages,
                offers: action.payload.offers,
                sliders: action.payload.sliders,
                reviews: action.payload.reviews,
                categories: action.payload.categories,
                showContactModal: false,
            };
        }
        case WIDGET_TOGGLE_SHOW_CONTACT_MODAL: {
            const { payload } = action as ReturnType<typeof widgetToggleShowContactModal>;
            return {
                ...state,
                showContactModal: payload.showContactModal,
            };
        }
        default:
            return state;
    }
};
