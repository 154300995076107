import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './advantages.scss';
import {AppState} from '../../../store/model';
import {connect} from 'react-redux';
import {Advantages} from '../../../store/widget/widget.model';
import {getAdvantagesContent} from '../../../store/widget/widget.selectors';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {FormattedMessage} from 'react-intl';
import calendar from '../../../assets/advantages/calendar.png';
import maintenance from '../../../assets/advantages/maintenance.png';
import money from '../../../assets/advantages/money.png';

interface ConnectedState {
    advantages: Advantages[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        advantages: getAdvantagesContent(state),
    }
}

class AdvantagesComponentInner extends React.PureComponent<ConnectedState> {

    get carouselItems() {
        return (this.props.advantages.map((item, key) => (
            <div key={key} className={'advantage-item'}>
                {item.iconName === 'image1' &&
                    <svg style={{width: '40px', height: '40px', color: 'green'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9L10,17Z" />
                    </svg>
                }

                {item.iconName === 'image2' &&
                    <svg style={{width: '40px', height: '40px', color: '#626262'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M10 6.2C10 4.3 8.8 2.6 7 2V5.7H4V2C2.2 2.6 1 4.3 1 6.2C1 8.1 2.2 9.8 4 10.4V21.4C4 21.8 4.2 22 4.5 22H6.5C6.8 22 7 21.8 7 21.5V10.5C8.8 9.9 10 8.2 10 6.2M16 8C16 8 15.9 8 16 8C12.1 8.1 9 11.2 9 15C9 18.9 12.1 22 16 22S23 18.9 23 15 19.9 8 16 8M16 20C13.2 20 11 17.8 11 15S13.2 10 16 10 21 12.2 21 15 18.8 20 16 20M15 11V16L18.6 18.2L19.4 17L16.5 15.3V11H15Z" />
                    </svg>
                }

                {item.iconName === 'image3' &&
                    <svg style={{width: '40px', height: '40px', color: '#626262'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20,6H16V4A2,2 0 0,0 14,2H10C8.89,2 8,2.89 8,4V6H4C2.89,6 2,6.89 2,8V19A2,2 0 0,0 4,21H20A2,2 0 0,0 22,19V8A2,2 0 0,0 20,6M10,4H14V6H10V4M12,9A2.5,2.5 0 0,1 14.5,11.5A2.5,2.5 0 0,1 12,14A2.5,2.5 0 0,1 9.5,11.5A2.5,2.5 0 0,1 12,9M17,19H7V17.75C7,16.37 9.24,15.25 12,15.25C14.76,15.25 17,16.37 17,17.75V19Z" />
                    </svg>
                }

                {item.iconName === 'money' &&
                    <img src={money} alt={'Advantages images'}/>
                }

                {item.iconName === 'calendar' &&
                    <img src={calendar} alt={'Advantages images'}/>
                }

                {item.iconName === 'maintenance' &&
                    <img src={maintenance} alt={'Advantages images'}/>
                }

                <p className='name'>{item.text}</p>
            </div>
        )));
    }

    public render() {

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 756 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 756, min: 0 },
                items: 1
            }
        };

        return (<div className={'advantages'}>
            <h2><FormattedMessage id='advantages.title'/></h2>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                autoPlay={true}
                // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                responsive={responsive}>
                {this.carouselItems}
            </Carousel>
        </div>);
    }
}

export const AdvantagesComponent = connect(mapStateToProps)(AdvantagesComponentInner);
