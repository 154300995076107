export const messages: string[] = [
    'us',
    'ru',
    'uz'
];

export interface SelectOption {
    value: string;
    label: string;
}

export const languages: SelectOption[] = [
    {
        value: 'us',
        label: 'English'
    },
    {
        value: 'ru',
        label: 'Pусский'
    },
    {
        value: 'uz',
        label: 'O\'zbekcha'
    }
]

export const defaultLocale: {} = {
    value: 'ru',
    label: 'Pусский'
}
