import {combineReducers} from 'redux';
import {LocaleReducer} from './locale/locale.reducer';
import {NotificationReducer} from './notification/notification.reducer';
import {AppState} from './model';
import {WidgetReducer} from './widget/widget.reducer';
import {ProductReducer} from './product/product.reducer';


export const reducers = {
    locale: LocaleReducer,
    notification: NotificationReducer,
    widget: WidgetReducer,
    product: ProductReducer,
};

export const rootReducer = combineReducers<AppState>(reducers);
