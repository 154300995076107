import {Category, Product, ProductStore} from './product.model';
import {
    PRODUCT_CHANGE_TRANSLATIONS,
    PRODUCT_SELECTED_CATEGORY,
    PRODUCT_SELECTED_PRODUCT, PRODUCT_SELECTED_PRODUCT_PAGE,
    productChangeTranslations,
    productSetCategoryId, productSetPageNumber, productSetProductId
} from './product.actions';
import { Reducer } from 'redux';

const INITIAL_STATE: ProductStore = {
    categories: [],
    pageNumber: 1,
};

export const ProductReducer: Reducer<ProductStore> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_CHANGE_TRANSLATIONS: {
            const { payload } = action as ReturnType<typeof productChangeTranslations>;

            const categories:Category[] = [];
            payload.categories.forEach(category => {
                const products:Product[] = [];
                payload.products.forEach(product => {
                    if (product.categoryId === category.id) {
                        products.push(product);
                    }
                });

                categories.push({
                    id: category.id,
                    name: category.name,
                    products: products
                });
            });
;

            return {
                ...state,
                categories: categories,
            };
        }
        case PRODUCT_SELECTED_CATEGORY: {
            const { payload } = action as ReturnType<typeof productSetCategoryId>;
            return {
                ...state,
                categoryId: payload.categoryId,
            };
        }
        case PRODUCT_SELECTED_PRODUCT: {
            const { payload } = action as ReturnType<typeof productSetProductId>;
            return {
                ...state,
                productId: payload.productId,
            };
        }
        case PRODUCT_SELECTED_PRODUCT_PAGE: {
            const { payload } = action as ReturnType<typeof productSetPageNumber>;
            return {
                ...state,
                pageNumber: payload.pageNumber,
            };
        }
        default:
            return state;
    }
};
