import * as React from 'react';
import Slider  from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider.css';
import './slider-1.scss';
import {
    SliderContent as SliderContentObject
} from '../../../store/widget/widget.model';
import {AppState} from '../../../store/model';
import {getSlidersContent, isShowContactModal} from '../../../store/widget/widget.selectors';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import sliderImage2 from '../../../assets/slider/slider-2.jpg';
import sliderImage3 from '../../../assets/slider/slider-3.jpg';
import sliderImage1 from '../../../assets/slider/slider-1.jpeg';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import telegramLogo from '../../../assets/Telegram_logo.svg';
import {Link} from 'react-router-dom';
import {getLanguageLocaleFromUrl} from '../../util/languageUtils';
import {Dispatch} from "redux";
import {widgetToggleShowContactModal} from "../../../store/widget/widget.actions";

interface ConnectedState {
    sliders: SliderContentObject[];
    showContactModal: boolean;
}

interface ConnectedDispatch {
    toggleShowContactModal: (showContactModal: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        toggleShowContactModal: (isShowContactModal) => dispatch(widgetToggleShowContactModal(isShowContactModal)),
    };
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        sliders: getSlidersContent(state),
        showContactModal: isShowContactModal(state),
    }
}

function getMatchingRoute(url) {
    const locale = getLanguageLocaleFromUrl();
    return url + '?lang=' + locale;
}

class SliderComponentInner extends React.PureComponent<ConnectedState & ConnectedDispatch> {
    constructor (props: ConnectedState & ConnectedDispatch) {
        super(props);

        this.toggleContactModal = this.toggleContactModal.bind(this)
    }

    toggleContactModal () {
        this.props.toggleShowContactModal(!this.props.showContactModal);
    }

    getBackgroundStyle(item) {
        if (item.image === '1-done-min.jpg') {
            return {
                backgroundImage: `url(${sliderImage1})`, backgroundPosition: 'center center'
            }
        } else if (item.image === '2-done-min.jpg') {
            return {
                backgroundImage: `url(${sliderImage2})`, backgroundPosition: 'center center'
            }
        } else if (item.image === '3-done-min.jpg') {
            return {
                backgroundImage: `url(${sliderImage3})`, backgroundPosition: 'center center'
            }
        } else {
            return {};
        }
    }


    public render() {

        const modalWidth = window.innerWidth > 1000 ? 600 : window.innerWidth - 30;

        return (
            <div>
                <Slider autoplay={5000}>
                {/*<Slider >*/}
                    {this.props.sliders.map((item, key) => (
                        <div
                            key={key}
                            style={this.getBackgroundStyle(item)}
                            className={'slide'}
                        >
                            <div className='center'>
                                <h1>{item.title}</h1>
                                <button>
                                    <Link to={getMatchingRoute(item.url)}>
                                            {item.button}
                                    </Link>
                                </button>
                                <button className={'non-primary'} onClick={this.toggleContactModal}><FormattedMessage id={'button.contact'} /></button>
                            </div>
                        </div>

                    ))}
                </Slider>

                <Rodal
                    animation={'rotate'}
                    duration={650}
                    visible={this.props.showContactModal}
                    width={modalWidth}
                    height={370}
                    className={'contact-modal'}
                    onClose={() => this.toggleContactModal()}>
                    <div style={{marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div>
                            <p>You can contact us via the following option:</p>
                            <div className={'options'}>
                                <div className={'option'}>
                                    <svg viewBox='0 0 24 24'>
                                        <path fill='currentColor' d='M6.6 10.8C8 13.6 10.4 15.9 13.2 17.4L15.4 15.2C15.7 14.9 16.1 14.8 16.4 15C17.5 15.4 18.7 15.6 20 15.6C20.6 15.6 21 16 21 16.6V20C21 20.6 20.6 21 20 21C10.6 21 3 13.4 3 4C3 3.4 3.5 3 4 3H7.5C8.1 3 8.5 3.4 8.5 4C8.5 5.2 8.7 6.4 9.1 7.6C9.2 7.9 9.1 8.3 8.9 8.6L6.6 10.8M14 3C13.4 3 13 3.4 13 4S13.4 5 14 5 15 4.6 15 4 14.6 3 14 3M17 3C16.4 3 16 3.4 16 4S16.4 5 17 5 18 4.6 18 4 17.6 3 17 3M20 3C19.4 3 19 3.4 19 4S19.4 5 20 5 21 4.6 21 4 20.6 3 20 3M14 6C13.4 6 13 6.4 13 7S13.4 8 14 8 15 7.6 15 7 14.6 6 14 6M17 6C16.4 6 16 6.4 16 7S16.4 8 17 8 18 7.6 18 7 17.6 6 17 6M20 6C19.4 6 19 6.4 19 7S19.4 8 20 8 21 7.6 21 7 20.6 6 20 6M14 9C13.4 9 13 9.4 13 10S13.4 11 14 11 15 10.6 15 10 14.6 9 14 9M17 9C16.4 9 16 9.4 16 10S16.4 11 17 11 18 10.6 18 10 17.6 9 17 9M20 9C19.4 9 19 9.4 19 10S19.4 11 20 11 21 10.6 21 10 20.6 9 20 9Z' />
                                    </svg>
                                    <a href='tel:+998901684477'>
                                        <FormattedMessage id={'page.home.contact.phone'} />
                                    </a>
                                </div>
                                <div className={'option'}>
                                    <img src={telegramLogo} alt='Telegram logo'/>
                                    <a href='https://t.me/barso_metal' target={'_blank'} rel={'noreferrer'}>
                                        <FormattedMessage id={'page.home.contact.telegram'} />
                                    </a>
                                </div>
                                <div className={'option'}>
                                    <svg viewBox='0 0 24 24'>
                                        <path fill='currentColor' d='M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z' />
                                    </svg>
                                    <a href='mailto:info@barso.uz' target={'_blank'} rel={'noreferrer'}>
                                        <FormattedMessage id={'page.home.contact.email'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Rodal>
            </div>
        );
    }
}

export const SliderComponent = connect(mapStateToProps, mapDispatchToProps)(SliderComponentInner);
