import {localeChangeAfterLoadSuccess, localeLoadTranslations, localeLoadTranslationsRequest} from './locale/locale.epics';
import {widgetChangeAfterLoadSuccessEpic, widgetLoadTranslationsEpic, widgetLoadTranslationsRequestEpic} from './widget/widget.epics';
import {productChangeAfterLoadSuccessEpic, productLoadTranslationsEpic, productLoadTranslationsRequestEpic} from './product/product.epics';

export const rootEpic = [
    localeLoadTranslations,
    localeLoadTranslationsRequest,
    localeChangeAfterLoadSuccess,
    widgetLoadTranslationsEpic,
    widgetLoadTranslationsRequestEpic,
    widgetChangeAfterLoadSuccessEpic,
    productLoadTranslationsEpic,
    productChangeAfterLoadSuccessEpic,
    productLoadTranslationsRequestEpic,
];
