import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './features.scss';
import {Features} from "../../../store/widget/widget.model";
import feature from '../../../assets/feature-1.jpg';

interface Props {
    features: Features[];
}

class FeaturesComponentInner extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    get getCleanedSliderContents() {
        var cleanedSliderContents: Features[] = [];

        Object.values(this.props.features).forEach(content => {

            if (content.frontTitle !== undefined) {
                cleanedSliderContents.push(content);
            }
        });

        return cleanedSliderContents;
    }

    public render() {


        return (
            <div className={'feature-card-container'}>
                {this.getCleanedSliderContents.map((item, key) => (
                    <div className="feature-card"
                         key={key}>
                        <div className="cols">
                            <div className={'col'}>
                                <div className="container">
                                    <div className="front" style={{backgroundImage: `url(${feature})`}}>
                                        <div className="inner">
                                            <h3>{item.frontTitle}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="inner">
                                            <h4>{item.backTitle}</h4>
                                            <p>{item.backDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                }
            </div>
        );
    }
}

export const FeaturesComponent = FeaturesComponentInner;
