import * as React from 'react';
import './home.scss';
import {SliderComponent} from '../widgets/slider/slider';
import Slider from 'react-animated-slider';
import {AdvantagesComponent} from "../widgets/advantages/advantages";
import feature1 from '../../assets/gallery/asas.png';
import feature2 from '../../assets/gallery/Door_to_door_delivery.jpg';
import feature3 from '../../assets/gallery/toezicht-en-beheer.jpg';
import {FormattedMessage, injectIntl, WrappedComponentProps as InjectedIntlProps} from "react-intl";
import {ReviewContent} from "../../store/widget/widget.model";
import {AppState} from "../../store/model";
import {getReviewsContent} from "../../store/widget/widget.selectors";
import {connect} from "react-redux";
import {OnlineFormComponent} from "../widgets/orderform/orderForm";

interface ConnectedState {
    reviews: ReviewContent[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        reviews: getReviewsContent(state),
    }
}

class HomeComponentInner extends React.PureComponent<InjectedIntlProps & ConnectedState> {

    render() {
        const {intl} = this.props;

        return (
            <div className={'home'}>
                <SliderComponent/>
                <AdvantagesComponent/>

                <div className={'feature-container-1'}>
                    <div className={'column'}>
                        <img src={feature1} alt={'Feature'}/>
                    </div>
                    <div className={'column'}>
                        <h1>01</h1>
                        <h2>{intl.formatMessage({id: 'page.home.feature1.title'})}</h2>
                        <h4>{intl.formatMessage({id: 'page.home.feature1.subtitle'})}</h4>
                        <p><FormattedMessage id='page.home.feature1.description'/></p>
                    </div>
                </div>

                <div className={'feature-container-2'}>
                    <div className={'column first'}>
                        <h1>02</h1>
                        <h2>{intl.formatMessage({id: 'page.home.feature2.title'})}</h2>
                        <h4>{intl.formatMessage({id: 'page.home.feature2.subtitle'})}</h4>
                        <p><FormattedMessage id='page.home.feature2.description'/></p>
                    </div>
                    <div className={'column'}>
                        <img src={feature2} alt={'Feature'}/>
                    </div>
                </div>

                <div className={'feature-container-1 third'}>
                    <div className={'column third'}>
                        <h1>03</h1>
                        <h2>{intl.formatMessage({id: 'page.home.feature3.title'})}</h2>
                        <h4>{intl.formatMessage({id: 'page.home.feature3.subtitle'})}</h4>
                        <p><FormattedMessage id='page.home.feature3.description'/></p>
                    </div>
                    <div className={'column'}>
                        <img src={feature3} alt={'Feature'}/>
                    </div>
                </div>

                <div className={'review-container'}>

                    <div className={'reviews'}>
                        <h2><FormattedMessage id='page.home.reviews.title'/></h2>
                        <Slider autoplay={12000}>
                            {this.props.reviews.map((item, key) => (
                                <div
                                    key={key}
                                    className={'slide'}
                                >
                                    <div className='center'>
                                        <p>{item.reviewText}</p>
                                        <h4><span>«{item.clientName}»</span> - {item.clientCompanyName}</h4>
                                    </div>
                                </div>

                            ))}
                        </Slider>
                    </div>
                </div>

                <div className="map-container">
                    <div style={{position:'relative', overflow:'hidden'}}>
                        <a
                            href="https://yandex.ru/maps/org/barso/147054071060/?utm_medium=mapframe&utm_source=maps"
                            style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0'}}
                            >Barso</a><a
                        href="https://yandex.ru/maps/10335/tashkent/category/metal_structures/184106658/?utm_medium=mapframe&utm_source=maps"
                        style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}
                        >Металлоконструкции в Ташкенте</a>
                        <iframe src="https://yandex.ru/map-widget/v1/-/CCUfQZTrCB" width="100%" height="500" frameBorder="1" allowFullScreen={true}
                                style={{position: 'relative'}}></iframe>
                    </div>
                </div>

                <OnlineFormComponent />
            </div>
        );
    }
}

export const HomeComponent = connect(mapStateToProps)(injectIntl(HomeComponentInner));
