import * as React from 'react';
import './subcategory.scss';
import {RouteComponentProps} from 'react-router-dom';
import {AppState} from '../../store/model';
import {connect} from 'react-redux';
import {Category, Subcategory} from "../../store/widget/widget.model";
import {getCategoryContent} from "../../store/widget/widget.selectors";
import {injectIntl, WrappedComponentProps as InjectedIntlProps} from "react-intl";
import ImageGallery from 'react-image-gallery';

interface IReactRouterParams {
    categoryId: string;
    subcategoryId: string;
}

interface ConnectedState {
    categories: Category[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        categories: getCategoryContent(state),
    }
}

class SubcategoryComponentInner extends React.PureComponent<ConnectedState & InjectedIntlProps & RouteComponentProps<IReactRouterParams>> {

    get findParentCategory() {
        return this.props.categories.find(c => c.id === this.props.match.params.categoryId);
    }

    get findCategory() {
        const parentCategory: Category | undefined = this.findParentCategory;

        if (parentCategory === undefined) {
            return undefined;
        }

        return parentCategory.subcategories.find(c => c.id === this.props.match.params.subcategoryId);
    }

    public render() {

        const subcategory: Subcategory | undefined = this.findCategory;
        const { intl } = this.props;

        return (
            <div className={'subcategory-page'}>
                {subcategory !== undefined &&
                    <div>
                        <div className={'header'}
                             style={{
                                 backgroundImage: `url(${subcategory.backgroundImage})`,
                                 backgroundSize: 'cover',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundColor: 'white'
                             }}>
                            <div className={'inner'}>
                                <div className={'center'}>
                                    <h2>{subcategory.title}</h2>
                                    <hr/>
                                    <p dangerouslySetInnerHTML={{__html: subcategory.description}}></p>
                                </div>
                            </div>
                        </div>
                        <div className={'content'}>
                            <div className={'left'}>
                                <ImageGallery
                                    showPlayButton={false}
                                    autoPlay={false}
                                    slideInterval={6000}
                                    items={subcategory.images}/>
                            </div>
                            <div className={'right'}>
                                <h2>{intl.formatMessage({id: 'description'})}</h2>
                                <ul>
                                    {subcategory.checklist.map((item, key) => (
                                        <li key={key}>
                                            <svg style={{width: '24px', height: '24px'}}
                                                 viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                            </svg>
                                            <span dangerouslySetInnerHTML={{__html: item}}></span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export const SubcategoryComponent = injectIntl(connect(mapStateToProps)(SubcategoryComponentInner));
