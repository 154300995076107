import * as React from 'react';
import './category.scss';
import {Link, RouteComponentProps} from 'react-router-dom';
import {AppState} from '../../store/model';
import {connect} from 'react-redux';
import {Category} from "../../store/widget/widget.model";
import {getCategoryContent} from "../../store/widget/widget.selectors";
import {injectIntl, WrappedComponentProps as InjectedIntlProps} from "react-intl";

interface IReactRouterParams {
    categoryId: string;
}

interface ConnectedState {
    categories: Category[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        categories: getCategoryContent(state),
    }
}

class CategoryComponentInner extends React.PureComponent<ConnectedState & InjectedIntlProps & RouteComponentProps<IReactRouterParams>> {

    get findCategory() {
        return this.props.categories.find(c => c.id === this.props.match.params.categoryId);
    }

    public render() {

        const { intl } = this.props;

        const category: Category | undefined = this.findCategory;
        return (
            <div className={'category-page'}>
                {category !== undefined &&
                    <div>
                        <div className={'header'}
                             style={{
                                 backgroundImage: `url(${category.backgroundImage})`,
                                 backgroundSize: 'cover',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundColor: 'white'
                             }}>
                            <div className={'inner'}>
                                <div className={'center'}>
                                    <h2>{category.title}</h2>
                                    <hr/>
                                    <p>{category.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className={'subcategories'}>

                            {category.subcategories.map((item, key) => (
                                <div className="card"
                                     style={{backgroundImage: `url(${item.previewImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                                     key={key}>
                                    <div className="card-content">
                                        <h2 className="card-title">{item.shortTitle.toLowerCase()}</h2>
                                        <p className="card-body">
                                            {item.shortDescription}
                                        </p>
                                        <Link to={`/category-c/${category?.id}/${item.id}/`}
                                              className="button">
                                            {intl.formatMessage({id: 'learn-more'})}
                                        </Link>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>

                }

                {category === undefined &&
                    <div>
                        <div className={'header super-category'}
                             style={{
                                 backgroundImage: `url(${this.props.categories[0] !== undefined ? this.props.categories[0].backgroundImage : ''})`,
                                 backgroundSize: 'cover',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 backgroundColor: 'white'
                             }}>
                            <div className={'inner'}>
                                <div className={'center'}>
                                    <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'footer.about-company.description'})}}></p>
                                </div>
                            </div>
                        </div>
                        <div className={'subcategories'}>
                            {this.props.categories.map((item, key) => (
                                <div className="card"
                                     style={{
                                         backgroundImage: `url(${item.previewImage})`,
                                         backgroundSize: 'contain',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundPosition: 'center'
                                     }}
                                     key={key}>
                                    <div className="card-content">
                                        <h2 className="card-title">{item.title.toLowerCase()}</h2>
                                        <p className="card-body">
                                            {item.description}
                                        </p>
                                        <Link to={`/category-c/${item.id}/`}
                                              className="button">
                                            {intl.formatMessage({id: 'learn-more'})}
                                        </Link>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export const CategoryComponent = injectIntl(connect(mapStateToProps)(CategoryComponentInner));
