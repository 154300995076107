export function changeLocaleInUrl(locale) {
    let url = window.location.href;

    const queryParams = getQueryParams();

    if (queryParams['lang'] !== undefined) {
        url = url.replaceAll('?lang=' + queryParams['lang'], '')
            .replaceAll('&lang=' + queryParams['lang'], '');
    }

    if (url.indexOf('?') > -1) {
        window.history.replaceState({}, '', url + '&lang=' + locale);
    } else {
        window.history.replaceState({}, '', url + '?lang=' + locale);
    }
}

export function getLanguageLocaleFromUrl() {
    const queryParameters = getQueryParams();
    return queryParameters['lang'];
}

export function getMatchingRoute(routeName) {
    const locale = getLanguageLocaleFromUrl();
    return '/' + routeName + `?lang=${locale}`;
}

export const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});
