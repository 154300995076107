import { ProductStoreContent } from './product.model';

export const PRODUCT_LOAD_TRANSLATIONS = '@@product/LOAD_SLIDER';
export const PRODUCT_LOAD_TRANSLATIONS_REQUEST = '@@product/LOAD_TRANSLATIONS_REQUEST';
export const PRODUCT_LOAD_TRANSLATIONS_SUCCESS = '@@product/LOAD_TRANSLATIONS_SUCCESS';
export const PRODUCT_CHANGE_TRANSLATIONS = '@@product/CHANGE_TRANSLATIONS';
export const PRODUCT_SELECTED_CATEGORY = '@@product/SELECTED_CATEGORY';
export const PRODUCT_SELECTED_PRODUCT = '@@product/SELECTED_PRODUCT';
export const PRODUCT_SELECTED_PRODUCT_PAGE = '@@product/SELECTED_PRODUCT_PAGE';

export const productLoadTranslations = (locale: string) => {
    return {
        type: PRODUCT_LOAD_TRANSLATIONS,
        payload: {
            locale
        }
    };
};

export const productLoadTranslationsRequest = (locale: string) => {
    return {
        type: PRODUCT_LOAD_TRANSLATIONS_REQUEST,
        payload: {
            locale
        }
    };
};

export const productLoadTranslationsSuccess = (productStoreContents: ProductStoreContent) => {
    return {
        type: PRODUCT_LOAD_TRANSLATIONS_SUCCESS,
        payload: {
            productStoreContents: productStoreContents,
        }
    };
};

export const productChangeTranslations = (productStoreContents: ProductStoreContent) => {
    return {
        type: PRODUCT_CHANGE_TRANSLATIONS,
        payload: {
            categories: productStoreContents.categories,
            products: productStoreContents.products,
        }
    };
};

export const productSetCategoryId = (categoryId: number) => {
    return {
        type: PRODUCT_SELECTED_CATEGORY,
        payload: {
            categoryId: categoryId
        },
    };
};

export const productSetProductId = (productId: string) => {
    return {
        type: PRODUCT_SELECTED_PRODUCT,
        payload: {
            productId: productId
        },
    };
};

export const productSetPageNumber = (pageNumber: number) => {
    return {
        type: PRODUCT_SELECTED_PRODUCT_PAGE,
        payload: {
            pageNumber: pageNumber
        },
    };
};
