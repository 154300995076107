import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './products.scss';
import { Dispatch } from 'redux';
import {AppState} from '../../store/model';
import {connect} from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import {Category, Product} from '../../store/product/product.model';
import {getCategories, getPageNumber, getSelectedCategoryId} from '../../store/product/product.selectors';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {productSetCategoryId, productSetPageNumber} from '../../store/product/product.actions';
import {changeCategory, getCategoryId, isNumber} from '../util/customUtils';
import {getLanguageLocaleFromUrl} from "../util/languageUtils";
import { slide as Menu } from 'react-burger-menu';

interface ConnectedState {
    categories: Category[];
}

interface InternalState {
    showMobileCategorySidebar: boolean;
    showLoadMore: boolean;
}

interface ConnectedState {
    selectedCategoryId: number | undefined;
    pageNumber: number;
}

interface ConnectedDispatch {
    setCategoryId: (categoryId: number) => void;
    setPageNumber: (pageNumber: number) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        categories: getCategories(state),
        selectedCategoryId: getSelectedCategoryId(state),
        pageNumber: getPageNumber(state),
    }
}

function buildUrlForProduct(categoryId, productId) {
    const locale = getLanguageLocaleFromUrl();
    return `/product/?lang=${locale}&pId=${productId}&cId=${categoryId}`;
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        setCategoryId: (categoryId) => dispatch(productSetCategoryId(categoryId)),
        setPageNumber: (pageNumber) => dispatch(productSetPageNumber(pageNumber)),
    }
}

class ProductsComponentInner extends React.PureComponent<ConnectedState & ConnectedDispatch, InternalState> {

    constructor(props: ConnectedState & ConnectedDispatch) {
        super(props);

        this.changeSelectedCategoryId = this.changeSelectedCategoryId.bind(this);
        this.showCategorySelectionSideBar = this.showCategorySelectionSideBar.bind(this);
        this.hideLoadMore = this.hideLoadMore.bind(this);
        this.showLoadMore = this.showLoadMore.bind(this);
        this.increasePageNumber = this.increasePageNumber.bind(this);
        this.initializePageNumber = this.initializePageNumber.bind(this);

        this.state = {
            showMobileCategorySidebar: false,
            showLoadMore: true,
        }
    }

    componentDidMount() {
        const categoryId = getCategoryId();
        if (categoryId !== '' && isNumber(categoryId)) {
            this.props.setCategoryId(Number(categoryId));
        } else {
            // load first category
            this.props.setCategoryId(1);
            changeCategory(1 + '');
        }
    }

    changeSelectedCategoryId = (categoryId: number)  => {
        changeCategory(categoryId + '');
        this.props.setCategoryId(Number(categoryId));
        this.initializePageNumber();
    }

    showCategorySelectionSideBar() {
        this.setState({showMobileCategorySidebar: !this.state.showMobileCategorySidebar});
    }

    hideLoadMore() {
        this.setState({showLoadMore: false});
    }

    showLoadMore() {
        this.setState({showLoadMore: true});
    }

    increasePageNumber() {
        this.props.setPageNumber(this.props.pageNumber + 1);
    }

    initializePageNumber() {
        this.props.setPageNumber(1);
    }

    get categories() {
        return (this.props.categories.map((item, key) => (
            <li key={key} className={'category-item'}
                onClick={() => this.changeSelectedCategoryId(item.id)}>
                {item.name}
            </li>
        )));
    }

    get categoryTitle() {

        const category = this.props.categories.find(category => { return category.id === this.props.selectedCategoryId});

        if (category === undefined) {
            return ('');
        }

        return (
            <h2>{category.name}</h2>
        )
    }

    get products() {
        let products:Product[] = [];
        let counter = 0;
        this.props.categories.forEach(category => {
           if (category.id === this.props.selectedCategoryId && counter <= this.props.pageNumber * 8) {
               products = category.products;
               counter = counter + 1;
           }
        });

        const visibleProducts:Product[] = [];
        products.forEach(product => {
            if (counter <= this.props.pageNumber * 8) {
                visibleProducts.push(product);

                counter = counter + 1;
            }
        });

        if (visibleProducts.length === products.length) {
            this.hideLoadMore();
        } else if (!this.state.showLoadMore) {
            this.showLoadMore();
        }

        return (visibleProducts.map((item, key) => (
            <div className={'product-item'} key={item.imageUrl}>
                <Link to={buildUrlForProduct(item.categoryId, item.id)}>
                    <img src={item.imageUrl} alt={'Product - ' + item.name}/>
                    <p className={'title'}>
                        {item.name}
                    </p>
                </Link>
            </div>
        )))
    }

    public render() {

        return (<div className={'product-page'}>
            <div className={'title-div'}>
                {this.categoryTitle}
            </div>
            <div className={'left-mobile'}>
                <p onClick={this.showCategorySelectionSideBar.bind(this)}>
                    <svg style={{width: '40px', height: '40px'}} viewBox="0 0 24 24">
                        <path fill="currentColor" d="M11 11L16.76 3.62A1 1 0 0 0 16.59 2.22A1 1 0 0 0 16 2H2A1 1 0 0 0 1.38 2.22A1 1 0 0 0 1.21 3.62L7 11V16.87A1 1 0 0 0 7.29 17.7L9.29 19.7A1 1 0 0 0 10.7 19.7A1 1 0 0 0 11 18.87V11M13 16L18 21L23 16Z" />
                    </svg>
                    <FormattedMessage id='page.products.categories.filter'/>
                </p>
                <Menu isOpen={ this.state.showMobileCategorySidebar }
                      onClose={ this.showCategorySelectionSideBar.bind(this) }
                      disableCloseOnEsc
                      menuClassName={ "sidebar" }>
                    <h2>
                        <FormattedMessage id='page.products.categories.title'/>
                    </h2>
                    <ul className={'categories'}>
                        {this.categories}
                    </ul>
                </Menu>
            </div>
            <div className={'left'}>
                <h2>
                    <FormattedMessage id='page.products.categories.title'/>
                </h2>
                <ul className={'categories'}>
                    {this.categories}
                </ul>
            </div>
            <div className={'right'}>
                {this.products}
            </div>

            {this.state.showLoadMore &&
				<div className={'load-more'}>
                <span onClick={ this.increasePageNumber.bind(this) }>
                    Load more
                </span>
				</div>
            }
        </div>);
    }
}

export const ProductsComponent = connect(mapStateToProps, mapDispatchToProps)(ProductsComponentInner);
