import * as React from 'react';
import './about.scss';
import image1 from '../../assets/gallery/asas.png';
import image3 from '../../assets/gallery/toezicht-en-beheer.jpg';
import image4 from '../../assets/gallery/uslugi-barso-150x150.jpg';
import image5 from '../../assets/gallery/stelaj-1-600x480.jpg';
import {FormattedMessage} from "react-intl";
import {OfferComponent} from "../widgets/offers/offers";
import {FeaturesComponent} from "../widgets/flipcard/features";
import {Features} from "../../store/widget/widget.model";
import {AppState} from "../../store/model";
import {getFeaturesContent} from "../../store/widget/widget.selectors";
import {connect} from "react-redux";

interface ConnectedState {
    features: Features[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        features: getFeaturesContent(state),
    }
}

class AboutComponent extends React.PureComponent<ConnectedState> {

    public render() {

        return (
            <div className={'about-page'}>
                <div className={'header'}>
                    <h2 style={{textAlign: 'center'}}><FormattedMessage id={'page.about.title'} /></h2>
                    <div className="gallery">
                        <div className="column">
                            <img src={image1} alt={'about our company'}/>
                        </div>
                        <div className="column second">
                            <img src={image5} alt={'about our company'}/>
                        </div>
                        <div className="column third">
                            <img src={image3} alt={'about our company'}/>
                        </div>
                        <div className="column">
                            <img src={image4} alt={'about our company'}/>
                        </div>
                    </div>
                </div>
                <div className={'separator'}>
                    <hr/>
                </div>
                <div className={'description'}>
                    <div className={'column'}>
                        <FormattedMessage id={'page.about.description.column1'} />
                    </div>
                    <div className={'column'}>
                        <FormattedMessage id={'page.about.description.column2'} />
                    </div>
                </div>

                <div className={'separator'}>
                    <hr/>
                </div>

                <div className={'offers-container'}>
                    <OfferComponent />
                </div>

                <FeaturesComponent
                features={this.props.features}/>
            </div>
        );
    }
}

export const About = connect(mapStateToProps)(AboutComponent);
