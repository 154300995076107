import { Epic, ofType } from 'redux-observable';
import { map, flatMap, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

import {
    localeLoadTranslationsRequest as localeLoadTranslationsRequestAction,
    localeLoadTranslationsSuccess as localeLoadTranslationsSuccessAction,
    localeChangeTranslations as localeChangeTranslationsAction,
    LOCALE_LOAD_TRANSLATIONS,
    LOCALE_LOAD_TRANSLATIONS_REQUEST,
    LOCALE_LOAD_TRANSLATIONS_SUCCESS,
} from './locale.actions';
import { messages } from '../../locales/messages';
import { errorNotificationAdd } from '../notification/notification.actions';
import { polyfillIntl } from '../../polyfills/intl.polyfill';

export const localeLoadTranslations: Epic =
    (action$) =>
        action$.pipe(ofType(LOCALE_LOAD_TRANSLATIONS),
                map(action => {
                    let locale = action.payload.locale;

                    if (locale.toLowerCase() === 'us') {
                        locale = 'en';
                    }

                    if (messages.includes(locale)) {
                        return localeLoadTranslationsRequestAction(locale);
                    }

                    // this is obsolete
                    if (messages.includes(locale.substring(0, 2))) {
                        return localeLoadTranslationsRequestAction(locale.substring(0, 2));
                    }

                    return localeLoadTranslationsRequestAction('en');
                })
            );

export const localeChangeAfterLoadSuccess: Epic =
    (action$) =>
        action$.pipe(ofType(LOCALE_LOAD_TRANSLATIONS_SUCCESS),
                mergeMap(({ payload }) =>
                    from(polyfillIntl(payload.locale.toLowerCase() === 'us' ? 'en' : payload.locale.toLowerCase()))
                        .pipe(
                            switchMap(() => [localeChangeTranslationsAction(payload.locale.toLowerCase() === 'us' ? 'en' : payload.locale.toLowerCase(), payload.messages)])
                        )
                )
            );

export const localeLoadTranslationsRequest: Epic =
    (action$) =>
        action$.pipe(ofType(LOCALE_LOAD_TRANSLATIONS_REQUEST),
            flatMap(({ payload }) =>
                from(import(`./../../locales/messages.${payload.locale}.json`))
                .pipe(
                    mergeMap(importedFile => {

                        if (payload.locale === 'en') {
                            return [localeLoadTranslationsSuccessAction(payload.locale, importedFile)];
                        }
                        return from(import(`./../../locales/messages.en.json`))
                            .pipe(
                                map(defaultFile => {
                                    return localeLoadTranslationsSuccessAction(payload.locale.toLowerCase() === 'us' ? 'en' : payload.locale.toLowerCase(), Object.assign({}, defaultFile, importedFile));
                                }),
                                catchError(() => {
                                    return [localeLoadTranslationsSuccessAction(payload.locale.toLowerCase() === 'us' ? 'en' : payload.locale.toLowerCase(), importedFile)];
                                })
                            );
                    }),
                    catchError(() => {
                        return [
                            errorNotificationAdd('Sorry, something went wrong. Please try again later.', {})
                        ];
                    })
                )
            ));
