import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './offers.scss';
import {AppState} from '../../../store/model';
import {connect} from 'react-redux';
import {Offers} from '../../../store/widget/widget.model';
import {getOffersContent} from '../../../store/widget/widget.selectors';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {FormattedMessage} from 'react-intl';

interface ConnectedState {
    offers: Offers[];
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        offers: getOffersContent(state),
    }
}

class OfferComponentInnner extends React.PureComponent<ConnectedState> {


    get carouselItems() {
        return (this.props.offers.map((item, key) => (
            <div key={key} className={'offer-item'}>
                <h2>{item.headline}</h2>
                <p>{item.body}</p>
            </div>
        )));
    }

    public render() {

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 756 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 756, min: 0 },
                items: 1
            }
        };

        return (<div className={'offers'}>
            <h2><FormattedMessage id='offers.title'/></h2>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                autoPlay={true}
                // autoPlay={this.props.deviceType !== 'mobile' ? true : false}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                responsive={responsive}>
                {this.carouselItems}
            </Carousel>
        </div>);
    }
}

export const OfferComponent = connect(mapStateToProps)(OfferComponentInnner);
