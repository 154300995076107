import { Epic, ofType } from 'redux-observable';
import { map, flatMap, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

import {
    productLoadTranslationsRequest as productLoadTranslationsRequestAction,
    productLoadTranslationsSuccess as productLoadTranslationsSuccessAction,
    productChangeTranslations as productChangeTranslationsAction,
    PRODUCT_LOAD_TRANSLATIONS,
    PRODUCT_LOAD_TRANSLATIONS_REQUEST,
    PRODUCT_LOAD_TRANSLATIONS_SUCCESS,
} from './product.actions';
import {defaultLocale, messages} from '../../locales/messages';
import { errorNotificationAdd } from '../notification/notification.actions';
import { polyfillIntl } from '../../polyfills/intl.polyfill';

export const productLoadTranslationsEpic: Epic =
    (action$) =>
        action$.pipe(ofType(PRODUCT_LOAD_TRANSLATIONS),
                map(action => {
                    const locale = action.payload.locale;

                    if (messages.includes(locale)) {
                        return productLoadTranslationsRequestAction(locale);
                    }

                    return productLoadTranslationsRequestAction(defaultLocale['value']);
                })
            );

export const productChangeAfterLoadSuccessEpic: Epic =
    (action$) =>
        action$.pipe(ofType(PRODUCT_LOAD_TRANSLATIONS_SUCCESS),
                mergeMap(({ payload }) =>
                    from(polyfillIntl(payload.locale))
                        .pipe(
                            switchMap(() => [productChangeTranslationsAction(payload.productStoreContents)])
                        )
                )
            );

export const productLoadTranslationsRequestEpic: Epic =
    (action$) =>
        action$.pipe(ofType(PRODUCT_LOAD_TRANSLATIONS_REQUEST),
            flatMap(({ payload }) =>
                from(import(`../../src/product/product.${payload.locale}.json`))
                .pipe(
                    mergeMap(importedFile => {

                        if (payload.locale === 'RU') {
                            return [productLoadTranslationsSuccessAction(importedFile)];
                        }
                        return from(import(`../../src/product/product.ru.json`))
                            .pipe(
                                map(defaultFile => {
                                    return productLoadTranslationsSuccessAction(Object.assign({}, defaultFile, importedFile));
                                }),
                                catchError(() => {
                                    return [productLoadTranslationsSuccessAction(importedFile)];
                                })
                            );
                    }),
                    catchError(() => {
                        return [
                            errorNotificationAdd('Sorry, something went wrong. Please try again later.', {})
                        ];
                    })
                )
            ));
