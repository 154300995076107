import {AppState} from '../model';
import {createSelector} from 'reselect';
import {ProductStore} from './product.model';

export const getProductStore = (state: AppState): ProductStore  => state.product;

export const getCategories = createSelector(
    [getProductStore],
    (productStore) => productStore.categories,
);

export const getSelectedCategoryId = createSelector(
    [getProductStore],
    (store: ProductStore): number | undefined => store.categoryId
);

export const getSelectedProductId = createSelector(
    [getProductStore],
    (store: ProductStore): string | undefined => store.productId
);

export const getPageNumber = createSelector(
    [getProductStore],
    (store: ProductStore): number => store.pageNumber
);

