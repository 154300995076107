import * as React from 'react';
import './footer.scss';
import './floating-button.scss';
import logo from '../../assets/logo/footerlogo.png';
import telegramLogo from '../../assets/Telegram_logo.svg';
import {FormattedMessage, injectIntl, WrappedComponentProps as InjectedIntlProps} from 'react-intl';
import {getMatchingRoute} from "../util/languageUtils";
import {Link} from "react-router-dom";
import Tooltip from 'react-power-tooltip'
import 'rodal/lib/rodal.css';

interface InternalState {
    showFloatingButton: boolean;
    showTooltip: boolean;
}

class FooterInner extends React.PureComponent<InjectedIntlProps, InternalState> {

    constructor(props: InjectedIntlProps) {
        super(props);

        this.toggleButton = this.toggleButton.bind(this);

        this.state = {
            showFloatingButton: false,
            showTooltip: false
        }
    }

    toggleButton() {
        this.setState({showFloatingButton: !this.state.showFloatingButton});
    }

    render() {
        const { intl } = this.props;

        return (
            <div>
                <div className={`multi-action-wrapper ${this.state.showFloatingButton ? "active" : ""}`}>
                    <div className='multi-action'>
                        <Tooltip show={this.state.showTooltip}
                                 textBoxWidth={'250px'}
                                 position="left center">
                            <h3>{intl.formatMessage({id: 'administrator'})}</h3>
                            <span>{intl.formatMessage({id: 'how-to-help'})}</span>
                        </Tooltip>

                        {this.state.showFloatingButton &&
                            <button className='action-button active'
                                    onClick={this.toggleButton}>
                                <svg style={{width: '24px'}} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                                </svg>
                            </button>
                        }

                        {!this.state.showFloatingButton &&
                            <button className='action-button'
                                    onClick={this.toggleButton}>
                                <div className="waves-block">
                                    <div className="waves wave-1"></div>
                                    <div className="waves wave-2"></div>
                                    <div className="waves wave-3"></div>
                                </div>
                                <svg style={{width: '25px', height: '25px'}} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2M12.19,5.5C11.3,5.5 10.59,5.68 10.05,6.04C9.5,6.4 9.22,7 9.27,7.69H11.24C11.24,7.41 11.34,7.2 11.5,7.06C11.7,6.92 11.92,6.85 12.19,6.85C12.5,6.85 12.77,6.93 12.95,7.11C13.13,7.28 13.22,7.5 13.22,7.8C13.22,8.08 13.14,8.33 13,8.54C12.83,8.76 12.62,8.94 12.36,9.08C11.84,9.4 11.5,9.68 11.29,9.92C11.1,10.16 11,10.5 11,11H13C13,10.72 13.05,10.5 13.14,10.32C13.23,10.15 13.4,10 13.66,9.85C14.12,9.64 14.5,9.36 14.79,9C15.08,8.63 15.23,8.24 15.23,7.8C15.23,7.1 14.96,6.54 14.42,6.12C13.88,5.71 13.13,5.5 12.19,5.5M11,12V14H13V12H11Z" />
                                </svg>
                            </button>
                        }

                        <ul className='actions'>
                            <li>
                            <span className={`${this.state.showFloatingButton ? "active" : ""}`}>
                                Telegram
                            </span>
                                <a href='https://t.me/barso_metal'
                                   target={'_blank'}
                                   rel={'noreferrer'}
                                   className={`${this.state.showFloatingButton ? "active" : ""}`}>
                                    <img src={telegramLogo} alt="Telegram icon"/>
                                </a>
                            </li>
                            <li>
                            <span className={`${this.state.showFloatingButton ? "active" : ""}`}>
                                {intl.formatMessage({id: 'page.home.contact.phone'})}
                            </span>
                                <a href='tel:+998901684477' style={{background: '#00e000'}}
                                   className={`${this.state.showFloatingButton ? "active" : ""}`}>
                                    <svg style={{width: '25px', height: '25px'}} viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <footer className='footer-distributed'>
                    <div className='footer-left'>
                        <img src={logo} alt={'Logo'}/>
                        <p className='footer-company-name'><FormattedMessage id='footer.copyright'/></p>
                    </div>

                    <div className='footer-center'>
                        <div className={'item title'}>
                            <FormattedMessage id='footer.contacts'/>
                        </div>

                        <div className={'item'}>
                            <div className={'icon-wrapper phone'}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5M5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5M19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19M15 12H17A5 5 0 0 0 12 7V9A3 3 0 0 1 15 12M19 12H21C21 7 16.97 3 12 3V5C15.86 5 19 8.13 19 12Z" />
                                </svg>
                            </div>
                            <p><a href="tel:+998977335075">+998 97 733 50 75</a></p>
                            <p><a href="tel:+998901684477">+998 90 168 44 77</a></p>
                            <p><a href="tel:+998994684477">+998 99 468 44 77</a></p>
                        </div>
                        <div className={'item'}>
                            <div className={'icon-wrapper'}>
                                <svg viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12 .64L8.23 3H5V5L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.11 2.9 20 4 20H20C21.11 20 22 19.11 22 18V8C22 7.27 21.61 6.64 21.03 6.29L19 5V3H15.77M7 5H17V9.88L12 13L7 9.88M8 6V7.5H16V6M5 7.38V8.63L4 8M19 7.38L20 8L19 8.63M8 8.5V10H16V8.5Z" />
                                </svg>
                            </div>
                            <p><a href='mailto:info@barso.uz'>info@barso.uz</a></p>
                        </div>
                    </div>
                    <div className='footer-center'>
                        <div className={'item title'}>
                            <FormattedMessage id='footer.contacts'/>
                        </div>

                        <div className={'item'}>
                            <ul>
                                <li>
                                    <Link to={getMatchingRoute('about')}>
                                        {intl.formatMessage({id: 'navbar.name.about'})}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={getMatchingRoute('about')}>
                                        {intl.formatMessage({id: 'footer.pages'})}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='footer-right'>
                        <p className='footer-company-about'>
                            <span className={'title'}><FormattedMessage id='footer.about-company.title'/></span>
                            <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'footer.about-company.description'})}}>
                            </span>
                        </p>
                    </div>
                </footer>
            </div>
        );
    }
}

export const Footer = injectIntl(FooterInner);
