import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './product.scss';
import {Dispatch} from 'redux';
import {AppState} from '../../store/model';
import {connect} from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import {Category, Product} from '../../store/product/product.model';
import {getCategories, getSelectedCategoryId, getSelectedProductId} from '../../store/product/product.selectors';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {productSetCategoryId, productSetProductId} from '../../store/product/product.actions';
import {getCategoryId, getProductId, isNumber} from '../util/customUtils';
import {getLanguageLocaleFromUrl} from '../util/languageUtils';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

interface ConnectedState {
    categories: Category[];
}

interface InternalState {
    showMobileCategorySidebar: boolean;
    isImageModalOpen: boolean;
}

interface ConnectedState {
    selectedCategoryId: number | undefined;
    selectedProductId: string | undefined;
}

interface ConnectedDispatch {
    setCategoryId: (categoryId: number) => void;
    setProductId: (categoryId: string) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        categories: getCategories(state),
        selectedCategoryId: getSelectedCategoryId(state),
        selectedProductId: getSelectedProductId(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        setCategoryId: (categoryId) => dispatch(productSetCategoryId(categoryId)),
        setProductId: (categoryId) => dispatch(productSetProductId(categoryId)),
    }
}

class ProductComponentInner extends React.PureComponent<ConnectedState & ConnectedDispatch, InternalState> {

    constructor(props: ConnectedState & ConnectedDispatch) {
        super(props);

        this.state = {
            showMobileCategorySidebar: false,
            isImageModalOpen: false,
        }

        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        const categoryId = getCategoryId();

        if (categoryId !== '' && isNumber(categoryId)) {
            this.props.setCategoryId(Number(categoryId));
        } else {
            this.props.setCategoryId(1);
        }

        let productId = getProductId();
        if (productId !== '') {
            this.props.setProductId(productId);
        }
    }

    openModal () {
        this.setState({isImageModalOpen: true})
    }

    public render() {
        const category:Category|undefined = this.props.categories.find(category => category.id === this.props.selectedCategoryId);
        let product:Product|undefined;

        if (category !== undefined) {
            product = category.products.find(product => product.id === this.props.selectedProductId);
        }

        let description:string;
        if (product?.description !== undefined) {
            description = product.description.replaceAll('\n', '<br />');
        } else {
            description = ''
        }

        const locale = getLanguageLocaleFromUrl();
        const width:number = window.innerWidth > 800 ? 700 : window.innerWidth - 10;

        return (<div className={'product-detail-page'}>
            <div className={'left'}>
                <div className={'image-container'}>
                    <img src={product?.imageUrl}
                         alt={'Product'}
                         onClick={this.openModal}/>
                </div>
            </div>
            <div className={'right'}>
                <div className={'navigation'}>
                    <Link to={`/lang=${locale}/`}>
                        <FormattedMessage id='navbar.name.home'/>
                    </Link> /
                    <Link to={`/category/?lang=${locale}&cId=${category?.id}`}>
                        {category?.name}
                    </Link> /
                    <Link to={`/product/?lang=${locale}&pId=${product?.id}`}
                        className={'product'}>
                        {product?.name}
                    </Link>
                </div>
                <h2>{product?.id} {product?.name}</h2>

                {description !== '' &&
                    <div className={'description'}>
						<h4><FormattedMessage id='page.products.categories.description'/>:</h4>
						<p dangerouslySetInnerHTML={{__html: description}}></p>
                    </div>

                }
            </div>

            <Rodal
                animation={'door'}
                duration={650}
                width={width}
                visible={this.state.isImageModalOpen}
                className={'contact-modal'}
                onClose={() => this.setState({ isImageModalOpen: false })}>
                <div>
                    <img src={product?.imageUrl} alt='Product detail modal'/>
                </div>
            </Rodal>
        </div>);
    }
}

export const ProductComponent = connect(mapStateToProps, mapDispatchToProps)(ProductComponentInner);
