import {getQueryParams} from "./languageUtils";
import {defaultLocale} from "../../locales/messages";

export function getCategoryId() {
    const queryParameters = getQueryParams();

    if (queryParameters['cId'] === undefined) {
        return '';
    } else {
        return queryParameters['cId'];
    }
}

export function getProductId() {
    const queryParameters = getQueryParams();

    if (queryParameters['pId'] === undefined) {
        return '';
    } else {
        return queryParameters['pId'];
    }
}

export function isNumber(value: string | number): boolean
{
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
}

export function changeCategory(categoryId: string) {
    const queryParameters = getQueryParams();

    let url = window.location.protocol + '//' + window.location.host;
    let locale = queryParameters['lang'];

    if (locale === undefined) {
        locale = defaultLocale['value'];
    }

    if (window.location.host === 'localhost') {
        url = url + ':' + window.location.port;
    }

    url = url + '/category/?lang=' + locale + '&cId=' + categoryId;

    window.history.pushState({urlPath:url}, '', url);
}
