import * as React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Dispatch } from 'redux';
import { AppState } from '../../store/model';
import { getLocale, getMessages } from '../../store/locale/locale.selectors';
import { localeLoadTranslations } from '../../store/locale/locale.actions';
import {getLanguageLocaleFromUrl} from '../util/languageUtils';
import {widgetLoadTranslations} from "../../store/widget/widget.actions";
import {defaultLocale} from "../../locales/messages";
import {productLoadTranslations} from "../../store/product/product.actions";


interface ConnectedState {
    translationLocale: string;
    translationMessages: {};
}

interface ConnectedDispatch {
    loadLocaleTranslations: (locale: string) => void;
    loadWidgetTranslations: (locale: string) => void;
    loadProductTranslations: (locale: string) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        translationLocale: getLocale(state),
        translationMessages: getMessages(state),
    };
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        loadLocaleTranslations: (locale) => dispatch(localeLoadTranslations(locale)),
        loadWidgetTranslations: locale => dispatch(widgetLoadTranslations(locale)),
        loadProductTranslations: locale => dispatch(productLoadTranslations(locale)),
    };
};

interface Props {
    children: JSX.Element[];
}

export class LocaleProviderComponent extends React.PureComponent<ConnectedState & ConnectedDispatch & Props> {

    componentDidMount() {
        const locale = this.localeFromUrl;
        this.props.loadLocaleTranslations(locale);
        this.props.loadWidgetTranslations(locale);
        this.props.loadProductTranslations(locale);
    }


    get localeFromUrl() {
        let locale = getLanguageLocaleFromUrl();

        if (locale === undefined || locale === '') {
            locale = defaultLocale['value'];
        }

        let url = window.location.href;

        if (url.indexOf('lang=' + locale) < 1) {
            if (url.indexOf('?') > -1) {
                window.location.replace(window.location.href + '&lang=' + locale);
            } else {
                window.location.replace(window.location.href + '?lang=' + locale);
            }
        }
        return locale;
    }

    render() {
        return (
            <IntlProvider
                key={this.props.translationLocale}
                locale={this.props.translationLocale}
                messages={this.props.translationMessages}
                textComponent='span'
            >
                <>
                {this.props.children}
                </>
            </IntlProvider>
        );
    }
}

export const LocaleProvider = connect(mapStateToProps, mapDispatchToProps)(LocaleProviderComponent);
